<template>
    <div class="blog">
        <widget-blog :folder="folder" :name="name"></widget-blog>
    </div>
</template>

<script>
import widgetBlog from '@/widget/widgetBlog.vue'

export default {
    name: 'Blog',
    components: {
        widgetBlog
    },
    data () {
        return {
            name: this.$route.params.name,
            folder: this.$route.params.folder
        }
    },
    methods: {},
    mounted () {
        console.log(this.$route.params.name)
        if (!this.$route.params.name) {
            this.$router.push('Home')
        }
    }
}
</script>

<style scoped>

</style>
