<template>
    <div class="widget-blog">
        <div class="menu"></div>
        <div class="content" v-html="content"></div>
    </div>
</template>

<script>
import showdown from 'showdown'
import 'highlight.js/styles/vs2015.css'
import showdownHighlight from 'showdown-highlight'

const converter = new showdown.Converter({
    tables: true,
    strikethrough: true,
    emoji: true,
    extensions: [showdownHighlight]
})

export default {
    name: 'widgetBlog',
    props: ['folder', 'name'],
    data: function () {
        return {
            content: ''
        }
    },
    methods: {
        load () {
            let url = '/note/' + (this.folder ? this.folder + '/' : '') + this.name + '.md'
            this.utils.get(url, {}).then(response => {
                this.content = converter.makeHtml(response)
            })
        }
    },
    mounted () {
        this.load()
    }
}
</script>

<style scoped>

.content {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
}

</style>

<style>

.widget-blog .content img {
    max-width: 100%;
}

</style>
